<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="mb-0 pb-0">
                <v-card-title class="mb-0 pb-0 px-0">Check-Ins</v-card-title>
            </v-col>
            <v-col class="pt-0 mt-0" cols="12">
                <v-switch
                    hide-details
                    v-model="filterCheckedoutMembers"
                    label="nur eingecheckte Mitglieder anzeigen"
                ></v-switch>
            </v-col>
            <v-col cols="12">
                <v-card-subtitle class="px-0 pt-0">
                    <span v-if="filterCheckedoutMembers">Aktuell eingecheckte Mitglieder</span>
                    <span v-else>Heute eingecheckte Mitglieder</span>
                    <v-chip v-if="!fetchingData" small class="ml-2">{{ sortedPool.length }}</v-chip>
                    <v-progress-circular
                        class="ml-4"
                        size="20"
                        v-else
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </v-card-subtitle>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    v-model="search"
                    append-inner-icon="mdi-magnify"
                    placeholder="Suche"
                    outlined
                    hide-details
                ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="sortedPool.length === 0">
                <v-alert type="info">Keine Check-Ins gefunden</v-alert>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="4"
                v-for="entry in sortedPool"
                :key="entry._id"
                class="d-flex align-center justify-center"
            >
                <v-card
                    height="100%"
                    @click="selectEntry(entry)"
                    style="position: relative"
                    width="100%"
                    min-height="300"
                    class="d-flex justify-center align-center flex-column py-5 rounded-xl"
                >
                    <div class="d-flex justify-center align-center flex-column">
                        <v-badge
                            v-if="loadingEntry !== entry._id"
                            :color="isCheckedIn(entry) ? 'green' : 'red'"
                            overlap
                            bordered
                            offset-y="20"
                            offset-x="30"
                        >
                            <v-avatar v-if="getPicture(entry)" size="80" color="primary">
                                <img style="object-fit: cover" v-if="getPicture(entry)" :src="getPicture(entry)" />
                            </v-avatar>
                            <v-avatar size="80" color="primary" v-else>
                                <span class="white--text text-h5">{{ getInitials(entry) }}</span>
                            </v-avatar>
                        </v-badge>
                        <v-progress-circular v-else size="80" indeterminate color="primary"></v-progress-circular>

                        <v-card-title class="h6 d-flex justify-space-between align-center pt-2">
                            <div>{{ entry.firstName }} {{ entry.lastName }}</div>
                        </v-card-title>

                        <span
                            @click.stop
                            style="position: absolute; right: 15px; top: 15px"
                            v-if="checkIfUserBirthday(entry)"
                        >
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <v-icon size="45" style="color: gold">mdi-cake-variant</v-icon>
                                    </span>
                                </template>
                                <span>hat heute Geburtstag</span>
                            </v-tooltip>
                        </span>

                        <v-card-subtitle class="d-flex align-center">
                            Interaktion
                            <v-icon size="15" class="ml-1" :color="interactionColors[entry._id]">mdi-circle</v-icon>
                        </v-card-subtitle>

                        <v-chip small v-if="entry.user && entry.user.startOfContract" class="mb-2">
                            Dabei seit: {{ getContractRuntime(entry).startDate }}
                        </v-chip>

                        <v-card-subtitle class="pb-0 mb-0 pt-0">
                            Check-in: {{ formatDate(entry.checkinDate) }} Uhr
                        </v-card-subtitle>
                        <v-card-subtitle class="pt-0" v-if="entry.checkoutDate">
                            Check-out: {{ formatDate(entry.checkoutDate) }} Uhr
                        </v-card-subtitle>

                        <v-chip class="mt-2 white--text" color="red" v-if="!entry.user" small>
                            Kein roody Account
                        </v-chip>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog max-width="500px" fullscreen :value="selectedEntry" persistent>
            <v-card style="position: relative; height: 100vh; overflow: scroll" v-if="selectedEntry">
                <div style="max-width: 800px; padding-bottom: 100px" class="d-flex flex-column mx-auto px-5">
                    <v-row no-gutters>
                        <v-col cols="12" class="d-flex justify-center align-center pt-5">
                            <v-avatar v-if="getPicture(selectedEntry)" size="180" color="primary">
                                <img
                                    style="object-fit: cover"
                                    v-if="getPicture(selectedEntry)"
                                    :src="getPicture(selectedEntry)"
                                />
                            </v-avatar>
                            <v-avatar size="180" color="primary" v-else>
                                <span class="white--text text-h5">{{ getInitials(selectedEntry) }}</span>
                            </v-avatar>
                        </v-col>
                    </v-row>

                    <v-card-title class="pb-0 pt-2 mx-auto">
                        <div>{{ selectedEntry.firstName }} {{ selectedEntry.lastName }}</div>
                    </v-card-title>
                    <v-card-text v-if="!selectedEntry.user">
                        E-Mail (TAC):
                        <span v-if="selectedEntry.email">
                            {{ selectedEntry.email }}
                            <v-icon class="ml-2" @click="copyText(selectedEntry.email)">mdi-content-copy</v-icon>
                        </span>
                        <v-chip v-else class="white--text" color="red" small>Keine E-Mail</v-chip>
                        <br />
                        <br />
                        E-Mail (roody):
                        <v-chip class="white--text" color="red" small>Kein roody Account</v-chip>
                    </v-card-text>
                    <v-card-text v-else class="d-flex justify-center">
                        <span>{{ selectedEntry.email }}</span>
                    </v-card-text>
                    <v-row class="pt-5" no-gutters>
                        <v-col class="d-flex justify-center" cols="12">
                            <v-card
                                max-width="400"
                                outlined
                                class="px-2 py-3 rounded-xl gradient-background"
                                v-if="selectedEntry.contract"
                            >
                                <v-row class="mx-5" no-gutters>
                                    <v-col cols="12">
                                        <v-card-title class="pa-0">
                                            {{ selectedEntry.contract.MemberCategoryName }}
                                        </v-card-title>
                                    </v-col>
                                    <v-col
                                        v-if="
                                            selectedEntry.contract &&
                                            selectedEntry.contract.ContractStartDate &&
                                            selectedEntry.contract.ContractEndDate
                                        "
                                        cols="12"
                                        class="pb-2"
                                    >
                                        {{ $moment(selectedEntry.contract.ContractStartDate).format('DD.MM.YYYY') }} bis
                                        {{ $moment(selectedEntry.contract.ContractEndDate).format('DD.MM.YYYY') }}
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        v-if="
                                            selectedEntry.contract.SubContracts &&
                                            selectedEntry.contract.SubContracts.SubContract &&
                                            selectedEntry.contract.SubContracts.SubContract.length > 0
                                        "
                                    >
                                        <v-chip
                                            small
                                            color="white"
                                            v-for="(
                                                subContract, index
                                            ) of selectedEntry.contract.SubContracts.SubContract.filter(
                                                (e) => e.ContractState === 1 || e.ContractState === 6
                                            )"
                                            :key="index + '-subcontract'"
                                            class="mr-2 my-1"
                                        >
                                            {{ subContract.MemberCategoryName }}
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row v-if="selectedEntry.user" class="mt-3" no-gutters>
                        <v-col class="mt-5" cols="12">
                            <v-card-title
                                :class="{ 'd-flex justify-space-between': $vuetify.breakpoint.smAndDown }"
                                class="py-0 my-0"
                            >
                                Interaktionen
                                <v-btn @click="createInteraction" icon x-small class="primary lighten-1 ml-3">
                                    <v-icon color="white">mdi-plus</v-icon>
                                </v-btn>
                            </v-card-title>
                        </v-col>
                        <v-col cols="12" v-if="!selectedEntry.customerInteractions.length > 0">
                            <v-card-subtitle>Keine Interaktionen gefunden</v-card-subtitle>
                        </v-col>
                        <v-col cols="12" v-else>
                            <div style="overflow: scroll">
                                <v-simple-table style="min-width: 700px">
                                    <thead>
                                        <tr>
                                            <th class="text-left">Datum</th>
                                            <th class="text-left">Wer</th>
                                            <th class="text-left">Wo</th>
                                            <th class="text-left">Was</th>
                                            <th class="text-left">Notiz</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="customerInteraction of selectedEntry.customerInteractions"
                                            :key="customerInteraction.interactions.date"
                                        >
                                            <td width="120">
                                                {{
                                                    $moment(customerInteraction.interactions.date).format('DD.MM.YYYY')
                                                }}
                                            </td>
                                            <td width="70">
                                                {{ customerInteraction.employee.vorname.slice(0, 1)
                                                }}{{ customerInteraction.employee.nachname.slice(0, 1) }}
                                            </td>
                                            <td width="150">
                                                {{ customerInteraction.interactions.location }}
                                            </td>
                                            <td width="200">
                                                {{ customerInteraction.interactions.reason }}
                                            </td>
                                            <td width="200">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <span v-bind="attrs" v-on="on">
                                                            {{
                                                                truncateText(customerInteraction.interactions.notes, 50)
                                                            }}
                                                        </span>
                                                    </template>
                                                    <span>{{ customerInteraction.interactions.notes }}</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </div>
                        </v-col>
                        <v-col class="mt-5" cols="12">
                            <v-card-title
                                :class="{ 'd-flex justify-space-between': $vuetify.breakpoint.smAndDown }"
                                class="py-0 my-0 mt-5"
                            >
                                Anamnese
                                <v-btn @click="createAnamnese" icon x-small class="primary lighten-1 ml-3">
                                    <v-icon color="white">mdi-plus</v-icon>
                                </v-btn>
                            </v-card-title>
                        </v-col>

                        <v-col cols="12">
                            <v-card-subtitle
                                class="pt-2"
                                v-if="
                                    !selectedEntry.documents ||
                                    ((!selectedEntry.documents.medicalHistory ||
                                        selectedEntry.documents.medicalHistory.length === 0) &&
                                        !selectedEntry.documents.anamnese)
                                "
                            >
                                Keine Anamnesebögen hinterlegt
                            </v-card-subtitle>
                        </v-col>
                        <v-col
                            v-if="selectedEntry.documents && selectedEntry.documents.medicalHistory"
                            align="center"
                            cols="12"
                            class="mt-2"
                        >
                            <v-expansion-panels flat>
                                <v-expansion-panel
                                    v-for="(medicalHistory, i) of selectedEntry.documents.medicalHistory"
                                    :key="i + '-medicalHistory'"
                                >
                                    <v-expansion-panel-header>
                                        Anamnese vom {{ $moment(medicalHistory.createdAt).format('DD.MM.YYYY') }}
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row>
                                            <v-col class="text-left" cols="7">
                                                Visum: {{ medicalHistory.creator.vorname }}
                                                {{ medicalHistory.creator.nachname }}
                                                {{ medicalHistory.creator.email }}
                                            </v-col>
                                            <v-col
                                                cols="5"
                                                class="px-1 py-0 ml-auto text-right"
                                                style="align-self: center"
                                            >
                                                <v-btn small @click="showMedicalHistory(medicalHistory._id)" icon>
                                                    <v-icon>mdi-eye</v-icon>
                                                </v-btn>
                                                <v-btn
                                                    small
                                                    @click="showMedicalHistory(medicalHistory._id, true)"
                                                    icon
                                                    color="blue"
                                                >
                                                    <v-icon>mdi-pencil</v-icon>
                                                </v-btn>
                                                <v-btn
                                                    small
                                                    @click="deleteMedicalHistory(medicalHistory._id)"
                                                    color="red"
                                                    icon
                                                >
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                        <v-col
                            v-if="selectedEntry.documents && selectedEntry.documents.anamnese"
                            class="d-flex justify-center"
                            cols="12"
                        >
                            <v-btn @click="showAnamesebogen" outlined class="mb-5 mx-auto">
                                alten Anamnesebogen öffnen
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card-title
                                :class="{ 'd-flex justify-space-between': $vuetify.breakpoint.smAndDown }"
                                class="pb-0"
                            >
                                Trainingspläne
                                <v-btn
                                    x-small
                                    @click="$router.push('/workouts/create')"
                                    icon
                                    class="primary lighten-1 ml-3"
                                >
                                    <v-icon color="white">mdi-plus</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-card-subtitle class="pt-5" v-if="selectedEntry.workouts.length == 0">
                                Keine Trainingspläne hinterlegt
                            </v-card-subtitle>
                            <v-simple-table v-if="selectedEntry.workouts.length > 0">
                                <thead>
                                    <tr>
                                        <th class="text-left">Letzte TP</th>
                                        <th class="text-left">Datum</th>
                                        <th class="text-left"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="workout of selectedEntry.workouts.slice(
                                            0,
                                            selectedEntry.showMoreWorkouts ? selectedEntry.workouts.length : 1
                                        )"
                                        :key="workout._id"
                                    >
                                        <td width="150">{{ workout.title }}</td>
                                        <td>
                                            {{ $moment(parseInt(workout.ts) * 1000).format('DD.MM.YYYY') }}
                                        </td>
                                        <td>
                                            <v-btn icon @click="$router.push('/workouts/edit/' + workout._id)">
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                            <v-row v-if="selectedEntry.workouts.length > 1" no-gutters>
                                <v-col cols="12" class="justify-center d-flex">
                                    <v-btn class="mb-2" text small @click="showMoreWorkouts">
                                        <span v-if="!selectedEntry.showMoreWorkouts">Mehr</span>

                                        <span v-else>weniger</span>
                                        <span class="pl-1">anzeigen</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card-title
                                :class="{ 'd-flex justify-space-between': $vuetify.breakpoint.smAndDown }"
                                class="pb-0"
                            >
                                Kurse
                            </v-card-title>
                            <v-card-subtitle class="pt-5" v-if="selectedEntry.courses.length === 0">
                                Keine Kurse besucht
                            </v-card-subtitle>

                            <v-simple-table v-if="selectedEntry.courses.length > 0">
                                <thead>
                                    <tr>
                                        <th class="text-left">Letzte Kurse</th>
                                        <th class="text-left">Datum</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="course of selectedEntry.courses.slice(
                                            0,
                                            selectedEntry.showMoreCourses ? selectedEntry.courses.length : 1
                                        )"
                                        :key="course._id"
                                    >
                                        <td>{{ course.course.name }}</td>
                                        <td>{{ course.time }}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                            <v-row v-if="selectedEntry.courses.length > 1" no-gutters>
                                <v-col cols="12" class="justify-center d-flex">
                                    <v-btn text small @click="showMoreCourses">
                                        <span v-if="!selectedEntry.showMoreCourses">Mehr</span>

                                        <span v-else>weniger</span>
                                        <span class="pl-1">anzeigen</span>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col cols="12" v-if="showAccountCreation">
                            <v-alert type="info">
                                Roody Account wurde erstellt und wird mit dem nächsten Check-In / Check-Out verknüpft.
                                Bitte schliesse diese Anzeige und öffne sie nach dem nächsten Check-In / Check-Out
                                erneut.
                            </v-alert>
                        </v-col>
                        <v-col v-if="!showAccountCreation" cols="12">
                            <v-alert type="info">
                                Kein roody Account gefunden. Bitte prüfe, ob ein roody Account vorliegt und aktualisiere
                                gegebenfalls die E-Mail im roody oder TAC oder erstelle einen neuen roody Account.
                            </v-alert>
                        </v-col>
                        <v-col v-if="!showAccountCreation" class="d-flex justify-center" cols="12">
                            <v-btn color="primary" @click="showRegisterDialog = true">
                                Neuen roody Account erstellen
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-card-actions
                        style="position: fixed; left: 0; bottom: 0; width: 100%; background-color: white"
                        class="d-flex justify-center"
                    >
                        <v-btn class="mr-5" @click="selectedEntry = null" text>Schliessen</v-btn>

                        <v-btn
                            v-if="selectedEntry.user"
                            @click="$router.push('/dashboard/user/search/' + selectedEntry.user._id)"
                            class="ml-5"
                            color="primary"
                        >
                            zum Profil
                        </v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>
        <anamnese-bogen-create @submitted="showGFRecommendations" ref="anamneseBogenRef"></anamnese-bogen-create>
        <healthcare-form
            @onClose="showNewHealthcareForm = false"
            @onSuccess="
                () => {
                    showNewHealthcareForm = false
                    fetchUserDocuments()
                }
            "
            ref="healthcareFormRef"
            :visible="showNewHealthcareForm"
        ></healthcare-form>
        <anamnesebogen ref="anamnesebogen"></anamnesebogen>
        <register
            @register="newAccountRegistered"
            :auto-login="false"
            :requirePassword="false"
            @close="showRegisterDialog = false"
            :active="showRegisterDialog"
        ></register>
        <customerInteraction @interactionCreated="interactionCreated" ref="customerInteraction" />
    </v-container>
</template>
  
<script>
import api from '@/api'
import userService from '@/services/userService'
import customerInteraction from '@/components/employee/customerInteraction.vue'
import healthcareForm from '@/views//dashboard/healthcareConfirmation/healthcareForm.vue'
import Anamnesebogen from '@/components/Anamnesebogen'
import anamneseBogenCreate from '@/components/anamneseBogenCreate'
import register from '@/views/launchScreen/register.vue'

export default {
    components: { healthcareForm, Anamnesebogen, anamneseBogenCreate, register, customerInteraction },
    data() {
        return {
            search: '',
            userService,
            showAccountCreation: false,
            showRegisterDialog: false,
            showNewHealthcareForm: false,
            fetchingData: true,
            filterCheckedoutMembers: true,
            pool: [],
            selectedEntry: null,
            loadingEntry: null,
            lastUpdatedAt: this.$moment().startOf('day').valueOf(), // Initial set to 1 AM of today
            visibleEntries: [],
        }
    },
    watch: {
        filterCheckedoutMembers() {
            this.fetchCheckinPool(true) // Full reload when the filter changes
        },
    },
    computed: {
        interactionColors() {
            return this.pool.reduce((colors, entry) => {
                colors[entry._id] = this.calculateInteractionColor(entry)
                return colors
            }, {})
        },
        sortedPool() {
            return this.pool
                .filter((e) => {
                    if (this.search.length === 0) return true
                    let fullName = (e.firstName + ' ' + e.lastName).toLowerCase()
                    let searchTerms = this.search.toLowerCase().trim().split(/\s+/) // Suche nach mehreren Begriffen

                    // Überprüfe, ob alle Suchbegriffe im vollständigen Namen vorkommen
                    return searchTerms.every((term) => fullName.includes(term))
                })
                .sort((a, b) => {
                    const dateA = Date.parse(a.checkoutDate || a.checkinDate)
                    const dateB = Date.parse(b.checkoutDate || b.checkinDate)

                    // Sortieren basierend auf dem aktuelleren Datum
                    return dateB - dateA
                })
        },
    },
    created() {
        this.fetchCheckinPool(true) // Load full data on initial load
        this.startPolling()
    },
    methods: {
        checkIfUserBirthday(entry) {
            if (entry && entry.birthday) {
                const today = this.$moment()
                const birthday = this.$moment(entry.birthday)
                // Überprüft, ob der Geburtstag am selben Tag und Monat ist (Jahr wird ignoriert)
                return today.isSame(birthday, 'day') && today.isSame(birthday, 'month')
            } else {
                return false
            }
        },
        truncateText(text, length) {
            return text.length > length ? text.substring(0, length) + '...' : text
        },
        intersectObserver(entry, isIntersecting) {
            // Setze isVisible auf true oder false, basierend auf der Sichtbarkeit
            if (isIntersecting) {
                this.$set(entry, 'isVisible', true)

                if (!this.visibleEntries.includes(entry._id)) {
                    this.visibleEntries.push(entry._id) // Füge sichtbaren Eintrag hinzu
                }
            } else {
                this.$set(entry, 'isVisible', false)
                this.visibleEntries = this.visibleEntries.filter((id) => id !== entry._id) // Entferne unsichtbaren Eintrag
            }
        },
        showGFRecommendations(userId) {
            if (this.userService.hasRight('ab_create')) this.$router.push('/gfrecommendations/' + userId)
        },
        interactionCreated() {
            this.fetchCheckinPool(true)
        },
        createInteraction() {
            this.$refs.customerInteraction.setVisible(this.selectedEntry.user._id)
        },
        calculateInteractionColor(entry) {
            if (!entry.customerInteractions || !entry.customerInteractions.length > 0) return 'red'

            let latestInteractionDate = this.getLatestInteractionDate(entry.customerInteractions)

            if (!latestInteractionDate) return 'red'

            let now = this.$moment()
            let interactionDate = this.$moment(latestInteractionDate)

            if (now.diff(interactionDate, 'months') > 2) {
                return 'orange'
            }

            return 'green'
        },

        getLatestInteractionDate(interactions) {
            return interactions[0]?.interactions?.date || null
        },
        copyText(text) {
            navigator.clipboard.writeText(text)
            this.$toast.success('In Zwischenablage gespeichert')
        },
        newAccountRegistered(data) {
            this.showRegisterDialog = false
            this.showAccountCreation = true
            this.$toast.success(
                'Neuer Account erfolgreich erstellt. Beim nächsten Check-In / Check-Out wird der Account mit TAC verknüpft'
            )
        },
        createAnamnese() {
            this.$refs.anamneseBogenRef.setVisible(null, false, false, this.selectedEntry.user._id)
        },
        showAnamesebogen() {
            alert('Bitte wechsle ins Profil um einen veralteten Anamensebogen zu öffnen oder erstelle einen neuen')
        },
        showMedicalHistory(id, editMode = false) {
            this.$refs.anamneseBogenRef.setVisible(id, editMode)
        },
        async deleteMedicalHistory(id) {
            if (confirm('Soll der Anamnesebogen wirklich gelöscht werden?')) {
                try {
                    await api.deleteMedicalHistory(id)
                    this.$toast.success('Erfolgreich gelöscht')
                } catch (e) {
                    this.$toast.error('Fehler beim löschen')
                }

                this.data.medicalHistory = this.data.medicalHistory.filter((e) => e._id != id)
            }
        },
        async fetchUserDocuments() {
            try {
                let documents = await api.getCustomerDocuments(this.selectedEntry.user._id)
                this.selectedEntry.documents = documents
                this.$forceUpdate()
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim Laden der Dokumente')
            }
        },
        getContractRuntime(entry) {
            let startDate = entry.user?.startOfContract || null
            let endDate = entry.user?.endOfContract || null

            startDate = this.$moment(startDate).format('DD.MM.YYYY') || this.$moment().format('DD.MM.YYYY')
            endDate = this.$moment(endDate).format('DD.MM.YYYY') || this.$moment().format('DD.MM.YYYY')

            // Berechne die Differenz in Tagen
            const startMoment = this.$moment(startDate, 'DD.MM.YYYY')
            const endMoment = this.$moment(endDate, 'DD.MM.YYYY')
            const wholeDuration = endMoment.diff(startMoment, 'days')
            const leftDurationDays = endMoment.diff(this.$moment(), 'days')

            return {
                startDate,
                endDate,
                wholeDuration,
                leftDurationDays,
            }
        },
        showMoreWorkouts() {
            this.selectedEntry.showMoreWorkouts = !this.selectedEntry.showMoreWorkouts
            this.$forceUpdate()
        },
        showMoreCourses() {
            this.selectedEntry.showMoreCourses = !this.selectedEntry.showMoreCourses
            this.$forceUpdate()
        },
        async selectEntry(entry) {
            this.showAccountCreation = false
            this.loadingEntry = entry._id
            let newEntry = entry
            newEntry.courses = []
            newEntry.workouts = []

            newEntry.contract = null
            newEntry.documents = null

            newEntry.showMoreCourses = false
            newEntry.showMoreWorkouts = false
            if (newEntry?.user) {
                try {
                    let documents = await api.getCustomerDocuments(newEntry.user._id)
                    newEntry.documents = documents
                } catch (e) {
                    this.$toast.error('Fehler beim Laden der Dokumente')
                }
                try {
                    let user = await api.fetchUserAttendanceStats(newEntry.user._id)
                    newEntry.courses = user.courses || []
                    newEntry.workouts = user.workouts || []
                } catch (e) {
                    this.$toast.error('Fehler beim Laden des roody Accounts')
                }
                try {
                    let contract = await api.fetchCurrentTACMemberDetailsByTACId(entry.tacId)

                    if (contract.Result.resultStatusFlag === 'SUCCESS' && contract.MemberDetail)
                        newEntry.contract = contract.MemberDetail
                } catch (e) {
                    console.log(e)
                    this.$toast.error('Fehler beim Laden der Vertragsdaten')
                }
            }
            this.$forceUpdate()
            this.selectedEntry = newEntry
            this.loadingEntry = false
        },
        getPicture(entry) {
            if (!entry.user) return null
            let picture = entry.user.tac_image
                ? 'https://app.wellcomefit.ch/api/checkin/pool/images/' + entry.user._id
                : null

            if (entry.user.bild) {
                picture = '/img/profilbilder' + entry.user.bild.split('.')[0] + '.webp'
            }

            return picture
        },
        getInitials(entry) {
            return entry.firstName.substr(0, 1) + entry.lastName.substr(0, 1)
        },
        isCheckedIn(entry) {
            return !entry.checkoutDate || this.$moment(entry.checkoutDate).isBefore(entry.checkinDate)
        },
        formatDate(date) {
            return this.$moment(date).format('HH:mm')
        },
        async fetchCheckinPool(fullReload = false) {
            this.fetchingData = true

            try {
                // Beim ersten Laden alle Daten laden oder wenn der Filter sich ändert

                let checkinPool = await api.fetchCheckinPool(
                    this.filterCheckedoutMembers,
                    fullReload ? this.$moment().startOf('day').valueOf() : this.lastUpdatedAt
                )
                /*// Initialisiere isVisible auf false
                checkinPool = checkinPool.map((entry) => {
                    // Behalte den isVisible-Status bei, wenn der Eintrag in visibleEntries ist
                    entry.isVisible = this.visibleEntries.includes(entry._id)
                    return { ...entry } // Hier eine Kopie der Einträge erstellen
                })*/

                if (fullReload) {
                    this.pool = checkinPool
                } else {
                    checkinPool.forEach((newItem) => {
                        const index = this.pool.findIndex((item) => item._id === newItem._id)

                        if (index !== -1) {
                            // Ersetze das vorhandene Element
                            this.pool[index] = newItem
                        } else {
                            // Füge das neue Element hinzu
                            this.pool.push(newItem)
                        }
                    })
                }

                // Aktualisiere den neuesten Zeitstempel
                if (checkinPool.length > 0) {
                    const latestUpdatedAt = checkinPool.reduce((latest, entry) => {
                        const updatedAt = this.$moment(entry.updatedAt).valueOf() // Konvertiere entry.updatedAt in einen Millisekunden-Zeitstempel
                        return updatedAt > latest ? updatedAt : latest // Vergleiche den aktuellen updatedAt mit dem bisherigen neuesten
                    }, 0) // Initialwert auf 0 gesetzt

                    // Konvertiere den neuesten Zeitstempel zurück in ein ISO-Format
                    this.lastUpdatedAt = this.$moment(latestUpdatedAt).valueOf()
                }
                if (this.selectedEntry) {
                    let findEntry = this.pool.find((e) => e._id === this.selectedEntry._id)
                    if (findEntry) this.selectEntry(findEntry)
                }
            } catch (e) {
                this.$toast.error('Fehler beim Laden der Checkins')
                this.$emit('error')
            }

            this.fetchingData = false
        },
        startPolling() {
            this.pollingInterval = setInterval(() => {
                this.fetchCheckinPool(false) // Only load new entries during polling
            }, 90000) // 1.5 Minuten
        },
    },
    beforeDestroy() {
        clearInterval(this.pollingInterval)
    },
}
</script>
  
<style scoped>
.gradient-background {
    background: linear-gradient(135deg, rgba(0, 150, 63, 1) 0%, rgba(0, 150, 63, 0.7) 50%, rgba(0, 150, 63, 0.4) 100%);
    border-radius: 15px;
    padding: 20px;
    color: white;
}
</style>
  