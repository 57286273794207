<template>
    <v-dialog v-model="visible" fullscreen dark close-on-back>
        <v-card dark>
            <v-row class="justify-content-center px-0 ma-0">
                <v-col cols="auto">
                    <div class="app-bar__image">
                        <lottie-player
                            mode="normal"
                            style="height: 60px; width: 80px"
                            name="roody_icon"
                            autoplay
                            src="/img/lottiefiles/roody_animated.json"
                        ></lottie-player>
                    </div>
                </v-col>
                <v-card-title class="pb-1 font-weight-bold">| Dein Ernährungspaket</v-card-title>
            </v-row>
            <v-form
                @submit.prevent
                ref="submissionForm"
                class="ma-auto"
                style="max-width: 800px"
                lazy-validation
                novalidate
            >
                <v-progress-linear
                    class="rounded mx-auto my-3"
                    style="background: grey; max-width: 800px"
                    :value="Math.round(((nutritionSaleFormStepper - 1) / 4) * 100)"
                    buffer-value="0"
                    height="20"
                ></v-progress-linear>
                <v-window v-model="nutritionSaleFormStepper" :touchless="!viewOnly" :continuous="false">
                    <v-window-item :value="1">
                        <v-card-title style="word-break: break-word">
                            Wähle einen Trainer und einen Kunden aus um mit dem erstellen des Ernährungspaketes zu
                            beginnen.
                        </v-card-title>
                        <v-col v-if="!disableUserFields" cols="12" class="mt-5 px-4 mx-auto">
                            <user-search
                                style="max-width: 80%"
                                class="mx-auto"
                                :value="formData.creator"
                                ignoreRights
                                instructor
                                :key="formData.creator"
                                label="Visum (Verkäufer)"
                                @update="updateUser($event, 'creator')"
                            ></user-search>
                        </v-col>
                        <v-col cols="12" class="mt-5 px-4 mx-auto" v-if="formData.creator && !disableUserFields">
                            <user-search
                                :key="formData.customer"
                                style="max-width: 80%"
                                class="mx-auto"
                                ignoreRights
                                :value="formData.customer"
                                :disabled="viewOnly || disableUserFields"
                                label="Kunde"
                                @update="updateUser($event, 'customer')"
                            ></user-search>
                        </v-col>
                        <v-card-actions class="justify-content-center">
                            <v-btn
                                v-if="!viewOnly && !formData.customer && formData.creator"
                                color="primary"
                                @click="showRegisterDialog = true"
                            >
                                Neuen Account erstellen
                            </v-btn>

                            <v-btn rounded v-if="formData.customer" class="mx-0" color="primary" @click="resetCustomer">
                                Kunde ändern
                            </v-btn>
                        </v-card-actions>
                    </v-window-item>
                    <v-window-item :value="2">
                        <v-card-title>Welches Paket passt am besten zu dir?</v-card-title>
                        <v-card-title>Laufzeit: {{ $moment().format('DD.MM.YYYY') }} bis {{ endDate }}</v-card-title>
                        <v-simple-table :class="{ 'mobile-table': $vuetify.breakpoint.xs }">
                            <thead>
                                <tr>
                                    <th class="text-left">Dienstleistungen</th>
                                    <th class="text-left">12-Monats-Paket</th>
                                    <th class="text-left">6-Monats-Paket</th>
                                    <th class="text-left">3-Monats-Paket</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(service, index) in services" :key="index">
                                    <td>{{ service.name }}</td>
                                    <td @click="selectedPackage = 0">
                                        <span
                                            class="font-weight-bold"
                                            v-if="service['12-Monats-Paket'] === '✔'"
                                            style="color: green"
                                        >
                                            ✔
                                        </span>
                                        <v-tooltip top v-else-if="service['12-Monats-Paket'].length">
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">
                                                    {{ service['12-Monats-Paket'][0] }}
                                                    <v-icon x-small>mdi-information</v-icon>
                                                </span>
                                            </template>
                                            <span>{{ service['12-Monats-Paket'][1] }}</span>
                                        </v-tooltip>

                                        <span v-else>{{ service['12-Monats-Paket'] }}</span>
                                    </td>
                                    <td @click="selectedPackage = 1">
                                        <span
                                            class="font-weight-bold"
                                            v-if="service['6-Monats-Paket'] === '✔'"
                                            style="color: green"
                                        >
                                            ✔
                                        </span>
                                        <v-tooltip top v-else-if="service['6-Monats-Paket'].length">
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">
                                                    {{ service['6-Monats-Paket'][0] }}
                                                    <v-icon x-small>mdi-information</v-icon>
                                                </span>
                                            </template>
                                            <span>{{ service['6-Monats-Paket'][1] }}</span>
                                        </v-tooltip>

                                        <span v-else>{{ service['6-Monats-Paket'] }}</span>
                                    </td>
                                    <td @click="selectedPackage = 2">
                                        <span
                                            class="font-weight-bold"
                                            v-if="service['3-Monats-Paket'] === '✔'"
                                            style="color: green"
                                        >
                                            ✔
                                        </span>
                                        <v-tooltip top v-else-if="service['3-Monats-Paket'].length">
                                            <template v-slot:activator="{ on, attrs }">
                                                <span v-bind="attrs" v-on="on">
                                                    {{ service['3-Monats-Paket'][0] }}
                                                    <v-icon x-small>mdi-information</v-icon>
                                                </span>
                                            </template>
                                            <span>{{ service['3-Monats-Paket'][1] }}</span>
                                        </v-tooltip>

                                        <span v-else>{{ service['3-Monats-Paket'] }}</span>
                                    </td>
                                </tr>
                                <!-- Zeile für die Preise -->
                                <tr>
                                    <td><strong>Preis</strong></td>
                                    <td @click="selectedPackage = 0"><strong>990 CHF</strong></td>
                                    <td @click="selectedPackage = 1"><strong>660 CHF</strong></td>
                                    <td @click="selectedPackage = 2"><strong>440 CHF</strong></td>
                                </tr>
                                <tr>
                                    <td><strong></strong></td>
                                    <td>
                                        <v-btn large icon @click="selectedPackage = 0">
                                            <v-icon :color="selectedPackage === 0 ? 'primary' : 'white'">
                                                {{
                                                    selectedPackage === 0
                                                        ? 'mdi-check-circle'
                                                        : 'mdi-check-circle-outline'
                                                }}
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                    <td>
                                        <v-btn large icon @click="selectedPackage = 1">
                                            <v-icon :color="selectedPackage === 1 ? 'primary' : 'white'">
                                                {{
                                                    selectedPackage === 1
                                                        ? 'mdi-check-circle'
                                                        : 'mdi-check-circle-outline'
                                                }}
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                    <td>
                                        <v-btn large icon @click="selectedPackage = 2">
                                            <v-icon :color="selectedPackage === 2 ? 'primary' : 'white'">
                                                {{
                                                    selectedPackage === 2
                                                        ? 'mdi-check-circle'
                                                        : 'mdi-check-circle-outline'
                                                }}
                                            </v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-window-item>
                    <v-window-item :value="3">
                        <v-card-title class="justify-content-center">
                            Vertragsbedingungen

                            <br />
                        </v-card-title>
                        <v-expansion-panels>
                            <v-expansion-panel v-for="(item, i) in contractTerms" :key="i">
                                <v-expansion-panel-header>{{ item.title }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    {{ item.text }}
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-card-text>
                            Das Mitglied bestätigt mit seiner Unterschrift die vorstehenden Mitgliedschaftsbedingungen
                            gelesen zu haben und zu akzeptieren.
                            <br />
                            Das Mitglied anerkennt sämtliche aus diesen und dem vorliegenden Vertrag ergebenden
                            Verpflichtungen.
                        </v-card-text>
                        <signature-pad
                            :key="updateSignature"
                            class="ma-auto"
                            darkMode
                            autoSaveSignature
                            @resetDrawing="formData.customerSignature = null"
                            @saveSignature="(signature) => (formData.customerSignature = signature)"
                        ></signature-pad>
                    </v-window-item>
                    <v-window-item v-if="!disableUserFields" :value="4">
                        <v-card-title class="justify-content-center">Zahlungsmethode</v-card-title>
                        <v-row>
                            <v-col cols="4">
                                <v-card
                                    min-height="80px"
                                    class="ma-1 justify-center align-center d-flex"
                                    :color="paymentOption === 'Bar' ? 'primary' : 'grey'"
                                    @click="paymentOption = 'Bar'"
                                >
                                    Bar
                                </v-card>
                            </v-col>
                            <v-col cols="4">
                                <v-card
                                    min-height="80px"
                                    class="ma-1 justify-center align-center d-flex"
                                    :color="paymentOption === 'Kartenzahlung' ? 'primary' : 'grey'"
                                    @click="paymentOption = 'Kartenzahlung'"
                                >
                                    Kartenzahlung
                                </v-card>
                            </v-col>
                            <v-col cols="4">
                                <v-card
                                    min-height="80px"
                                    class="ma-1 justify-center align-center d-flex"
                                    :color="paymentOption === 'Rechnung' ? 'primary' : 'grey'"
                                    @click="paymentOption = 'Rechnung'"
                                >
                                    Rechnung
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-card-title class="justify-content-center">Notizen</v-card-title>
                        <v-row>
                            <v-col cols="12">
                                <v-textarea label="notizen" v-model="notes"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-card-title class="justify-content-center">Unterschrift Trainer</v-card-title>
                        <signature-pad
                            :key="updateSignature"
                            class="ma-auto"
                            darkMode
                            :disabled="viewOnly"
                            :signatureData="viewOnly ? formData.creatorSignature : null"
                            autoSaveSignature
                            @resetDrawing="formData.creatorSignature = null"
                            @saveSignature="(signature) => (formData.creatorSignature = signature)"
                        ></signature-pad>
                    </v-window-item>
                </v-window>
                <v-card-actions class="justify-content-center">
                    <v-btn
                        v-if="nutritionSaleFormStepper > 1"
                        x-large
                        rounded
                        color="grey"
                        class="ma-auto"
                        @click="nutritionSaleFormStepper--"
                    >
                        zurück
                    </v-btn>
                    <v-btn
                        x-large
                        rounded
                        color="primary"
                        class="ma-auto"
                        :loading="submittingNutritionSaleForm"
                        @click="validateStepAndGoNext()"
                        :disabled="!stepFinished"
                    >
                        {{ nutritionSaleFormStepper === 4 ? 'Abschliessen' : 'weiter' }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
        <register
            @register="newAccountRegistered()"
            :auto-login="false"
            :requirePassword="false"
            @close="showRegisterDialog = false"
            :active="showRegisterDialog"
        ></register>
        <v-btn icon color="grey" @click="setVisibleFalse()" x-large fixed bottom left right class="mx-auto">
            <v-icon x-large>mdi-close-circle-outline</v-icon>
        </v-btn>
    </v-dialog>
</template>

  <script>
import register from '@/views/launchScreen/register.vue'
import userSearch from '@/components/userSearch.vue'
import signaturePad from '@/components/signaturePad/signaturePad.vue'
import api from '@/api'

import { mapActions } from 'vuex'

export default {
    components: {
        register,
        userSearch,
        signaturePad,
    },
    data() {
        return {
            paymentOption: 'Rechnung',
            notes: '',
            disableUserFields: false,
            updateSignature: 0,
            viewOnly: false,
            selectedPackage: 0,
            visible: false,
            showRegisterDialog: false,
            nutritionSaleFormStepper: 1,
            contractTerms: [
                {
                    title: 'Bedingung 1',
                    text: 'Die Leistungen stehen jedem Mitglied, gemäss diesem Vertrag, während der Vertragsdauer zur Verfügung. Die Konsumation von Getränken (nicht Offenausschank) und Nahrungsmitteln, sowie die Teilnahme an Spezialveranstaltungen, sind im Preis nicht inbegriffen. Die Gebühren sind aus der aktuellen Preisliste ersichtlich. Das Nichtbenützen der Einrichtungen und Leistungen berechtigt nicht zur Reduktion oder Rückforderung der Beiträge. Der Group Fitness Plan kann jederzeit geändert werden. Zudem besteht kein Anspruch auf einen bestimmten Instruktor oder Instruktorin.',
                },
                {
                    title: 'Bedingung 2',
                    text: 'Dieser Vertrag endet automatisch nach Vertragsende. (Datum siehe Vertragsablauf)',
                },
                {
                    title: 'Bedingung 3',
                    text: 'Im Falle von Schliessungen, zum Beispiel infolge Reinigung oder Revision und für Betriebsunterbrüche infolge höherer Gewalt (Epidemien/Pandemien) und anderer unvorhersehbarer Ereignisse, besteht für das Mitglied keinerlei Anspruch auf Rückvergütungen/Timestops etc.',
                },
                {
                    title: 'Bedingung 4',
                    text: 'Mit Ausnahme der Betriebshaftpflicht haften wir nicht für Unfälle und Wertgegenstände. Der Abschluss einer Unfall- und/oder Diebstahlversicherung ist Sache des Mitglieds. Während den Öffnungszeiten ist das Center immer überwacht.',
                },
                {
                    title: 'Bedingung 5',
                    text: 'Den Anweisungen des Personals ist Folge zu leisten. Die Hygienevorschriften sind strikt zu beachten. Grobe oder wiederholte Verstösse haben den Entzug der Mitgliedschaft, ohne Anspruch auf Rückerstattung zur Folge.',
                },
                {
                    title: 'Bedingung 6',
                    text: 'Beim Umgang mit Daten hält sich well come FIT an die geltende Gesetzgebung, insbesondere an das Datenschutzrecht. well come FIT erhebt, speichert und bearbeitet nur Daten, die für die Erbringung der Dienstleistungen, für die Abwicklung und Pflege der Kundenbeziehungen, namentlich die Gewährleistung einer hohen Dienstleistungsqualität, für die Sicherheit von Betrieb und Infrastruktur, sowie für die Rechnungsstellung benötigt werden. Der Kunde willigt ein, dass well come FIT im Zusammenhang mit Abschluss und Abwicklung des Vertrages Auskünfte über ihn einholen bzw. Daten betreffend seinem Zahlverhalten weitergeben kann seine Daten zu Inkassozwecken an Dritte weitergeben darf seine Daten für Marketingzwecke bearbeiten darf, namentlich für die bedarfsgerechte Gestaltung und Entwicklung ihrer Dienstleistungen. Der Kunde kann die Verwendung seiner Daten zu Marketingzwecken einschränken oder untersagen lassen.',
                },
                {
                    title: 'Bedingung 7',
                    text: 'Die well come FIT AG überwacht seine Fitnessstudios mit Videokameras und speichert die Aufnahmen, soweit und solange dies im Einzelfall zur Sicherheit seiner Mitglieder und Aufklärung von Straftaten erforderlich ist. Der Umstand der Beobachtung und die verantwortliche Stelle werden durch Hinweisschilder erkennbar gemacht. Die einschlägigen Bestimmungen werden eingehalten.',
                },
                {
                    title: 'Bedingung 8',
                    text: 'Sollte eine oder mehrere Bestimmungen dieses Vertrages unwirksam sein, so lässt dies die Wirksamkeit des Vertrages, sowie der übrigen Bestimmungen unberührt.',
                },
                {
                    title: 'Bedingung 9',
                    text: 'Für alle aus diesem Vertrag entstehenden Streitigkeiten gilt als ausschliesslicher Gerichtsstand der Hauptsitz der well come FIT AG in Frauenfeld. ',
                },
            ],
            servicesByIndex: ['12-Monats-Paket', '6-Monats-Paket', '3-Monats-Paket'],
            services: [
                {
                    name: 'Initialgespräch (Vor Ort, 45 Minuten)',
                    '12-Monats-Paket': '✔',
                    '6-Monats-Paket': '✔',
                    '3-Monats-Paket': '✔',
                },
                {
                    name: 'Ernährungsplan (Individuell & Rezepte)',
                    '12-Monats-Paket': '✔',
                    '6-Monats-Paket': '✔',
                    '3-Monats-Paket': '✔',
                },
                {
                    name: 'Rezeptanpassungen (wenn nötig)',
                    '12-Monats-Paket': '✔',
                    '6-Monats-Paket': '✔',
                    '3-Monats-Paket': '✔',
                },
                {
                    name: 'Follow-up-Gespräche',
                    '12-Monats-Paket': [
                        '13 Follow-Up-Gespräche',
                        'Im ersten Monat alle 2 Wochen zur Festlegung der Ziele und ersten Anpassungen. \n Danach regelmäßige Termine alle 4 Wochen für die kontinuierliche Begleitung über das Jahr.',
                    ],
                    '6-Monats-Paket': [
                        '4 Follow-Up-Gespräche',
                        'In den ersten beiden Monaten alle 4 Wochen zur Besprechung der Fortschritte und Anpassung der Ziele. \n Anschließend alle 6 Wochen für die fortlaufende Unterstützung.',
                    ],
                    '3-Monats-Paket': ['2 Follow-Up Gespräche', 'Nach der Hälfte der Zeit und zum Abschluss'],
                },
                {
                    name: 'Körperanalyse',
                    '12-Monats-Paket': ['16 Messungen', 'Alle 2 Wochen für 2 Monate, danach 12x nach Wahl'],
                    '6-Monats-Paket': ['8 Messungen', 'Alle 2 Wochen für 2 Monate, dann 1x im Monat'],
                    '3-Monats-Paket': ['4 Messungen', '2x im ersten Monat, danach 1x im Monat'],
                },
            ],
            submittingNutritionSaleForm: false,
            pages: [],
            formData: {
                customerSignature: null,
                creator: null,
                customer: null,
                selection: null,
            },
            rules: {
                required: [(v) => !!v || 'Dieses Feld ist erforderlich'],
                requiredArray: [(v) => !!v.length || 'Mindestens eine Option auswählen'],
            },
        }
    },
    created() {
        if (window.location.href.includes('localhost')) {
            console.log('SETTING USER')
            this.formData.creator = '5f55fdad6716c132adf93baa'
            this.formData.customer = '5f55fdad6716c132adf93baa'
        }
    },
    computed: {
        endDate() {
            let selectionMonths = [12, 6, 3]

            console.log(this.selectedPackage, 'PACKAGE')

            return this.$moment()
                .add(selectionMonths[this.selectedPackage], 'months')
                .subtract(1, 'days')
                .format('DD.MM.YYYY')
        },
        stepFinished() {
            if (this.nutritionSaleFormStepper === 1 && (!this.formData.creator || !this.formData.customer)) return false
            return true
        },
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationbarVisibility']),
        validateStepAndGoNext() {
            // VALIDATION FOR DEFAULT FORM FIELDS
            if (!this.$refs.submissionForm.validate()) {
                setTimeout(() => {
                    let errorElement = document.getElementsByClassName('error--text')
                    if (errorElement && errorElement.length > 0) {
                        errorElement[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }
                }, 100)
                return
            }
            // VALIDATION FOR SELECTS

            let error = false

            if (
                (this.nutritionSaleFormStepper === 3 && !this.formData.customerSignature) ||
                (this.nutritionSaleFormStepper === 4 && !this.formData.creatorSignature)
            ) {
                alert('Bitte unterschreiben Sie das Formular')
                error = true
            }

            if (!error) {
                if (this.nutritionSaleFormStepper === 4) {
                    console.log('heyyyy i submitte jetzt')
                    this.submitAnamnese()
                    return
                }
                this.nutritionSaleFormStepper++
            }
        },
        async submitAnamnese() {
            let data = {
                customer: this.formData.customer,
                creator: this.formData.creator,
                customerSignature: this.formData.customerSignature,
                creatorSignature: this.formData.creatorSignature,
                selection: this.selectedPackage,
                paymentOption: this.paymentOption,
                notes: this.notes,
            }
            this.submittingNutritionSaleForm = true
            try {
                let status = await api.submitNutritionSaleForm(data)
                this.$toast.success('Erfolgreich erstellt')

                this.setVisibleFalse()
            } catch (e) {
                this.$toast.error('Fehler beim Erstellen')
            } finally {
                this.submittingNutritionSaleForm = false
            }
        },
        setVisibleFalse() {
            this.visible = false
            this.viewOnly = false
            this.nutritionSaleFormStepper = 1
            this.paymentOption = 'Rechnung'
            this.notes = ''
            this.formData = {
                customerSignature: null,
                creator: null,
                customer: null,
                selection: null,
            }
            this.updateSignature++
            this.submittingNutritionSaleForm = false
            this.setNavigationbarVisibility(true)
            this.$emit('closeAnamnesebogen')
        },
        resetCustomer() {
            this.formData.customer = null
        },

        async setVisible() {
            this.visible = true
        },
        newAccountRegistered() {
            this.showRegisterDialog = false
            this.$toast.success('Neuer Account erfolgreich erstellt. Du kannst den Kunden nun auswählen')
        },
        updateUser(id, type) {
            this.formData[type] = id
        },
    },
}
</script>
  
  <style scoped lang="scss">
$clouds: #ecf0f1;
$midnight: var(--v-primary);
$wisteria: white;
::v-deep .mobile-table {
    width: 160vw !important; /* Wendet 160vw an */
    overflow-x: auto; /* Ermöglicht horizontales Scrollen */
}

/* Stelle sicher, dass der innere Table-Wrapper ebenfalls 160vw hat */
::v-deep .mobile-table .v-data-table__wrapper {
    width: 160vw !important;
}

::v-deep .mobile-table table {
    width: 160vw !important; /* Wendet 160vw auf das eigentliche <table> an */
}
body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: $wisteria;
    color: white;
}
header {
    position: fixed;
    top: 0;
    padding: 1em;
    background: var(--v-primary);
    a {
        color: $wisteria;
        font-weight: bold;
    }
}
h1 {
    margin: 0 0 1em;
}
.group {
    display: flex;
    align-items: center;
    margin-bottom: 2em;
}
input[type='checkbox'],
input[type='radio'] {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
label {
    position: relative;
    margin-right: 1em;
    padding-left: 2em;
    padding-right: 1em;
    line-height: 2;
    cursor: pointer;
    &:before {
        box-sizing: border-box;
        content: ' ';
        position: absolute;
        top: 0.3em;
        left: 0;
        display: block;
        width: 1.4em;
        height: 1.4em;
        border: 2px solid var(--v-primary);
        border-radius: 0.25em;
        z-index: -1;
    }
}
input[type='radio'] + label::before {
    border-radius: 1em;
}
.selectsDisabled + label {
    cursor: not-allowed;
    &:before {
        border: 2px solid white;
        background: grey !important;
    }
}
/* Checked */
input[type='checkbox']:checked + label,
input[type='radio']:checked + label {
    padding-left: 1em;
    color: $wisteria;
    &:before {
        top: 0;
        width: 100%;
        height: 2em;
        background: var(--v-primary);
    }
}

/* Transition */
label,
label::before {
    -webkit-transition: 0.25s all ease;
    -o-transition: 0.25s all ease;
    transition: 0.25s all ease;
}
</style>